// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  providers: 'app',
  baseURL: 'https://mycio-api.ciodev.accenture.com',
  msal: {
    clientId: "37ae90af-f318-493b-bcf5-1f99dd783532",
    authority: "https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22",
    redirectUri: "https://italypcrequests.ciodev.accenture.com/",
    scopes: ["37ae90af-f318-493b-bcf5-1f99dd783532/user_impersonation"]
    
  }
};
