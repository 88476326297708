import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'rebar-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    standalone: true,
    imports: [RouterLink, NgIf]
})
// export class FooterComponent implements OnInit, AfterViewInit {
export class FooterComponent implements OnInit{
  constructor() { }

  termsBox = false;
  currentYear = new Date().getFullYear();
  ngOnInit() {
  }
  OpenTerms(){
    this.termsBox = true;
    console.log(this.termsBox);
  }
  CloseTerms(){
    this.termsBox = false;
  }

}
