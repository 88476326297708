import { NgModule, ModuleWithProviders, InjectionToken, Provider } from '@angular/core';

import {
  MsalModule,
  MsalInterceptor,
  MsalGuard,
  MsalService,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';

import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
  BrowserAuthOptions,
  stubbedPublicClientApplication,
} from '@azure/msal-browser';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AppConfigService } from '../services/app-config.service';

export const REBAR_AUTH_GUARD = new InjectionToken<string>('REBAR_AUTH_GUARD');
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

/*
  When running locally `npm run start:local` or `npm run test` or `npm run e2e`
  use mock values, to turn off security.  This is set at build time.
*/

export const PROVIDERS = {
  mock: [
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: { client: 'mock', authority: 'mock' },
    },
    {
      provide: REBAR_AUTH_GUARD,
      useValue: emptyGuard,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useValue: {},
    },
    {
      provide: MSAL_INSTANCE,
      useValue: stubbedPublicClientApplication,
    },
    MsalService,
  ],
  app: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AppConfigService],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AppConfigService],
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: REBAR_AUTH_GUARD, useClass: MsalGuard },
  ] as Provider[],
};

@NgModule({
  imports: [MsalModule],
})
export class RebarAuthModule {
  static forRoot(): ModuleWithProviders<RebarAuthModule> {
    return {
      ngModule: RebarAuthModule,
      providers: PROVIDERS[environment.providers],
    };
  }
}

export function emptyGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  return true;
}

export function MSALInstanceFactory(
  config: AppConfigService
): IPublicClientApplication {
  const msal = config.config['msal'] as Record<string, BrowserAuthOptions>;
  let msalAuth;

  if (msal) {
    msalAuth = msal['auth'];
  } else {
    console.error('Configure msal in ' + AppConfigService.configPath);
  }

  return new PublicClientApplication({
    auth: msalAuth as BrowserAuthOptions,
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(config: AppConfigService): MsalInterceptorConfiguration {
  const msal = config.config['msal'] as Record<
    string,
    Record<
      string,
      Record<string, Iterable<readonly [unknown, unknown]> | unknown>
    >
  >;

  const protectedResourceMap = new Map(
    msal['auth']['framework']['protectedResourceMap'] as Iterable<
      readonly [string, string[]]
    >
  );
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
    loginFailedRoute: '/403',
  };
}

export function loggerCallback(logLevel: LogLevel, message: string): void {
	// This console log is annoying af
	//console.log(message); 
}
