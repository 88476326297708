<nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #460073;">
    <div class="navTitle navbar-header">
        <a routerLink="/Home" class="navbar-brand" id="headingLogo">
            <fa-icon [icon]="faHome"></fa-icon>&nbsp;&nbsp;
        </a>

        <a class="navbar-brand" routerLink="/Home" id="heading">HOME</a>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto" id="parent">
<!--             <li class="nav-item active">
                <a class="nav-link" routerLink="/Home">
                    <fa-icon [icon]="faHome"></fa-icon> HOME
                </a>
            </li> -->
            <li class="nav-item dropdown active">
                <button id="SupBox" mat-button [matMenuTriggerFor]="menu">
                    <fa-icon [icon]="faQuestionCircle"></fa-icon> SUPPORT
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="showSupportBox()">Support</button>
                    <button mat-menu-item (click)="showAboutBox()">About</button>
                </mat-menu>
            </li>
            <li class="nav-item active">
                <a class="nav-link" href="https://people.accenture.com/People/" target="_blank">
                    Welcome, {{AppUser?.enterpriseId}}!
                </a>
            </li>
            <li class="nav-item active">
                <a class="nav-link" (click)="Logout()">
                    <fa-icon [icon]="faSignOutAlt"></fa-icon> Logout
                </a>
            </li>
            <li class="nav-item active">
                <a class="nav-link">
                    <fa-icon [icon]="faBars" (click)="sidenav.toggle()"></fa-icon>
                </a>
            </li>

        </ul>
    </div>
</nav>

<mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" position="end">
        <mat-nav-list>
            <mat-list-item class="mat-list-item-custom" routerLink="/Home" (click)="sidenav.toggle()">
                <fa-icon [icon]="faHome"></fa-icon>&nbsp;Home
            </mat-list-item>
            <a [routerLink]="['/PCRequest/All']">
                <mat-list-item class="mat-list-item-custom" *ngIf="!AppUser?.isServiceProvider" (click)="sidenav.toggle()">
                    <fa-icon [icon]="faDesktop"></fa-icon>&nbsp;PC Requests
                </mat-list-item>
            </a>
            <a [routerLink]="['/PCRequest/Archived']" *ngIf="AppUser?.isSuperUser || AppUser?.isSuperUserPlus">
                <mat-list-item class="mat-list-item-custom" (click)="sidenav.toggle()">
                    <fa-icon [icon]="faDesktop"></fa-icon>&nbsp;ALL Archived Request
                </mat-list-item>
            </a>
            <ng-contain *ngIf="AppUser?.isDeveloper || AppUser?.isAdministrator || AppUser?.isSuperUser || AppUser?.isSuperUserPlus">
                <mat-expansion-panel *ngIf="AppUser?.isAdministrator" (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false">
                    <mat-expansion-panel-header style="padding: 0px 16px;">
                        <mat-panel-title class="mat-list-item-custom">
                            <fa-icon [icon]="faUser"></fa-icon>&nbsp;Administration
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <a [routerLink]="['/Admin/dashboard']">
                        <mat-list-item class="menu2 text-nowrap mat-list-item-custom" routerLink="/dashboard" (click)="sidenav.toggle()">
                            <fa-icon [icon]="faTachometerAlt"></fa-icon>&nbsp;Dashboard
                        </mat-list-item>
                    </a>
                    <a [routerLink]="['/Admin/ChargeCodeControl']">
                        <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="sidenav.toggle()">
                            <fa-icon [icon]="faDollarSign"></fa-icon>&nbsp;Charge Code Control
                        </mat-list-item>
                    </a>
                    <a [routerLink]="['/Admin/SponsorNotification']">
                        <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="sidenav.toggle()">
                            <fa-icon [icon]="faBell"></fa-icon>&nbsp;Sponsor Notifications
                        </mat-list-item>
                    </a>
                    <a [routerLink]="['/Admin/Users']">
                        <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="sidenav.toggle()">
                            <fa-icon [icon]="faUsers"></fa-icon>&nbsp;Users
                        </mat-list-item>
                    </a>
                    <a [routerLink]="['/Admin/MailTemplates']">
                        <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="sidenav.toggle()">
                            <fa-icon [icon]="faEnvelope"></fa-icon>&nbsp;Mail Templates
                        </mat-list-item>
                    </a>
                    <hr>
                    <p>Data Managment</p>
                    <a [routerLink]="['/Admin/OfficeDelivery']">
                        <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="sidenav.toggle()">
                            <fa-icon [icon]="faCogs"></fa-icon>&nbsp;Office Delivery
                        </mat-list-item>
                    </a>
                    <a [routerLink]="['/Admin/PCModel']">
                        <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="sidenav.toggle()">
                            <fa-icon [icon]="faCogs"></fa-icon>&nbsp;PC Models
                        </mat-list-item>
                    </a>
                    <a [routerLink]="['/Admin/PCType']">
                        <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="sidenav.toggle()">
                            <fa-icon [icon]="faCogs"></fa-icon>&nbsp;PC Types
                        </mat-list-item>
                    </a>
                    <hr>
                    <ng-contain *ngIf="AppUser?.isDeveloper">
                        <p>Developer Tools</p>
                        <a [routerLink]="['/Admin/Settings']">
                            <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="sidenav.toggle()">
                                <fa-icon [icon]="faCog"></fa-icon>&nbsp;Settings
                            </mat-list-item>
                        </a>
                        <a [routerLink]="['/Admin/Log/Error']">
                            <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="sidenav.toggle()">
                                <fa-icon [icon]="faExclamationTriangle"></fa-icon>&nbsp;Error Log
                            </mat-list-item>
                        </a>
                        <a [routerLink]="['/Admin/Log/Event']">
                            <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="sidenav.toggle()">
                                <fa-icon [icon]="faInfo"></fa-icon>&nbsp;Event Log
                            </mat-list-item>
                        </a>
                    </ng-contain>
                </mat-expansion-panel>
                <a [routerLink]="['/Admin/News']">
                    <mat-list-item (click)="sidenav.toggle()" class="mat-list-item-custom">
                        <fa-icon [icon]="faNewspaper"></fa-icon>&nbsp;News
                    </mat-list-item>
                </a>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header style="padding: 0px 16px;">
                        <mat-panel-title class="mat-list-item-custom">
                            <fa-icon [icon]="faBook"></fa-icon>&nbsp;Reports
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="ExportAllOfALL()" (click)="sidenav.toggle()">
                        <fa-icon [icon]="faFileExcel"></fa-icon>&nbsp;All of All
                    </mat-list-item>
                    <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="ExportMonthlyCheck()" (click)="sidenav.toggle()">
                        <fa-icon [icon]="faFileExcel"></fa-icon>&nbsp;Monthly Check
                    </mat-list-item>
                    <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="ExportRental()" (click)="sidenav.toggle()">
                        <fa-icon [icon]="faFileExcel"></fa-icon>&nbsp;Rental
                    </mat-list-item>
                    <mat-list-item class="menu2 text-nowrap mat-list-item-custom" (click)="ExportRentalNaplesDC()" (click)="sidenav.toggle()">
                        <fa-icon [icon]="faFileExcel"></fa-icon>&nbsp;Rental Naples DC
                    </mat-list-item>
                </mat-expansion-panel>
            </ng-contain>
            <mat-list-item class="mat-list-item-custom" *ngIf="AppUser?.isServiceProvider" routerLink="/PCRequest/RequestsToProcess" (click)="sidenav.toggle()">
                <fa-icon [icon]="faCheckSquare"></fa-icon>&nbsp;Requests To Process
            </mat-list-item>

        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <span class="spacer"><br></span>
        <router-outlet></router-outlet>

    </mat-sidenav-content>
</mat-sidenav-container>



<rebar-support-box [supportbox]="supportBox" [aboutbox]="aboutBox" (messageEvent)="receiveMessage($event)">
</rebar-support-box>