import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InitObjects } from 'src/app/shared/init-objects/init-objects';
import { AppUser } from '../../core/interfaces/AppUser';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';
import { ExportDataService } from '../../shared/services/export-data.service';
import { UsersService } from '../users/service/users.service';
import { MatCardModule } from '@angular/material/card';
import { NgIf } from '@angular/common';

@Component({
    selector: 'rebar-export',
    templateUrl: './export.component.html',
    styleUrls: ['./export.component.css'],
    standalone: true,
    imports: [NgIf, MatCardModule]
})
export class ExportComponent implements OnInit {

  constructor(
    private userService: UsersService,
    private exportDataService: ExportDataService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService) { }

  errorMessage = '';
  type = '';
  title = '';
  isValid = false;

  AppUser: AppUser = InitObjects.getInitAppUser();


  ngOnInit() {
    this.loadAppUser();

  }
  loadAppUser() {
    this.userService.getAppUser().subscribe(
      item => {
        this.AppUser = item;
        if (this.AppUser.isDeveloper || this.AppUser.isAdministrator || this.AppUser.isSuperUser || this.AppUser.isSuperUserPlus){
          this.isValid = true;
          this.route.params.subscribe(params => {
            this.type = params.type;
            switch (this.type) {
              case 'AllOfAll': {
                this.ExportAllOfALL();
                this.title = 'All of All';
                break;
              }
              case 'MonthlyCheck': {
                this.ExportMonthlyCheck();
                this.title = 'Monthly Check';
                break;
              }
              case 'Rental': {
                this.ExportRental();
                this.title = 'Rental';
                break;
              }
              case 'RentalNaplesDC': {
                this.ExportRentalNaplesDC();
                this.title = 'Rental Naples DC';
                break;
              }
              default: {
                // statements;
                break;
              }
            }
          });
        }
      }, error => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      });
  }

  //#region EXPORTS
  ExportAllOfALL() {
    this.exportDataService.exportAllOfAll().subscribe(
      (res: any) => {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(res.image);
        element.download = 'AllOfAllRequests.xlsx';
        document.body.appendChild(element);
        element.click();
        // this.toastr.success('File exported successfully', 'Success!');
      }, (error :any) => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      });
  }
  ExportMonthlyCheck() {
    this.exportDataService.exportMonthlyCheck().subscribe(
      (res: any) => {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(res.image);
        element.download = 'MonthlyCheck.xlsx';
        document.body.appendChild(element);
        element.click();
        // this.toastr.success('File exported successfully', 'Success!');
      }, (error :any) => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      });
  }
  ExportRental() {
    this.exportDataService.exportRental().subscribe(
      (res: any) => {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(res.image);
        element.download = 'Rental.xlsx';
        document.body.appendChild(element);
        element.click();
        // this.toastr.success('File exported successfully', 'Success!');
      }, (error :any) => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      });
  }
  ExportRentalNaplesDC() {
    this.exportDataService.exportRentalNaplesDC().subscribe(
      (res: any) => {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(res.image);
        element.download = 'Rental_NaplesDC.xlsx';
        document.body.appendChild(element);
        element.click();
        // this.toastr.success('File exported successfully', 'Success!');
      }, (error :any) => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      });
  }
  //#endregion

}
