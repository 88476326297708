import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { Observable } from 'rxjs';
import { Analytics } from '../../core/interfaces/Analytics';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private Url = 'italypcreqapi/analytics';

  constructor(private repo: RepositoryService) { }

  getAnalytics(): Observable<Analytics> {
    return this.repo.getData(this.Url + '/GetAnalytics');
  }
  login(): Observable<Analytics> {
    return this.repo.getData(this.Url + '/Login');
  }
  getPCRequestStatusCount(): Observable<any> {
    return this.repo.getData(this.Url + '/GetPCRequestStatusCount');
  }
  getPCTypeCount(): Observable<any> {
    return this.repo.getData(this.Url + '/GetPCTypeCount');
  }
  getTop5PCModel(): Observable<any> {
    return this.repo.getData(this.Url + '/GetTop5PCModel');
  }
}
