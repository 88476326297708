import { Component, OnInit } from '@angular/core';
import {
  faDesktop, faQuestionCircle, faSignOutAlt, faBars,
  faHome, faUsers, faUser, faBook, faFileExcel, faEnvelope,
  faBell, faTachometerAlt, faCogs, faCog, faNewspaper,
  faExclamationTriangle, faInfo, faDollarSign, faCheckSquare
} from '@fortawesome/free-solid-svg-icons';
import { UsersService } from '../../feature/users/service/users.service';
import { ErrorHandlerService } from '../services/error-handler.service';
import { AppUser } from '../../core/interfaces/AppUser';
import { ExportDataService } from '../services/export-data.service';
import { PeopleService } from '../services/people.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RebarAuthService } from '../../core/rebarauth/rebar.auth.service';
import { SupportBoxComponent } from '../support-box/support-box.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgIf } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { RouterLink, RouterOutlet } from '@angular/router';

@Component({
    selector: 'rebar-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: true,
    imports: [RouterLink, FaIconComponent, MatButtonModule, MatMenuModule, MatSidenavModule, MatListModule, NgIf, MatExpansionModule, RouterOutlet, SupportBoxComponent]
})

export class HeaderComponent implements OnInit {

  constructor(
    private userService: UsersService,
    private exportDataService: ExportDataService,
    private peopleService: PeopleService,
    private sanitizer: DomSanitizer,
    private auth: RebarAuthService,
    private errorHandler: ErrorHandlerService) { }

  faDesktop = faDesktop;
  faQuestionCircle = faQuestionCircle;
  faSignOutAlt = faSignOutAlt;
  faNewspaper = faNewspaper;
  faBars = faBars;
  faHome = faHome;
  faUsers = faUsers;
  faUser = faUser;
  faBook = faBook;
  faFileExcel = faFileExcel;
  faBell = faBell;
  faEnvelope = faEnvelope;
  faTachometerAlt = faTachometerAlt;
  faCog = faCog;
  faCogs = faCogs;
  faExclamationTriangle = faExclamationTriangle;
  faDollarSign = faDollarSign;
  faInfo = faInfo;
  faCheckSquare = faCheckSquare;


  // Expansion in sidenav
  panelOpenState = false;

  private visible = false;

  supportBox = false;

  aboutBox = false;
  AppUser: AppUser = {
      enterpriseId: '',
      isDeveloper: false,
      isAdministrator: false,
      isServiceProvider: false,
      isSuperUser: false,
      isSuperUserPlus: false,
      roles: []
  }

  errorMessage ='';

  //#region Porfile Picture
  public profileImage: any = 'assets/img/NoUser.png';
  private showSidebar() {
    this.visible = !this.visible;
  }
  showSupportBox() {
    this.supportBox = true;
  }

  receiveMessage($event: boolean) {
    this.supportBox = $event;
    this.aboutBox = $event;
  }
  showAboutBox() {
    this.aboutBox = true;
  }

  ngOnInit() {
    this.loadAppUser();
  }
  loadAppUser() {
    this.userService.getAppUser().subscribe(
      item => {
        this.AppUser = item;
        this.loadProfilePic();
      }, error => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      });
  }

  Logout() {
    this.auth.logout();
  }
  loadProfilePic() {
    this.peopleService.getProfilePicture(this.AppUser.enterpriseId).subscribe(
      item => {
        const objectURL = 'data:image/png;base64,' + item;
        this.profileImage = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }, error => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      });
  }
  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load',
      () => {
        this.profileImage = reader.result;
        this.profileImage = this.sanitizer.bypassSecurityTrustUrl(this.profileImage);
        console.log(this.profileImage);
      },
      false);

    if (image) {
      if (image.type !== 'application/pdf') {
        reader.readAsDataURL(image);
      }
    }
  }

  //#endregion

  //#region EXPORTS
  ExportAllOfALL() {
    this.exportDataService.exportAllOfAll().subscribe(
      (res: any) => {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(res.image);
        element.download = 'AllOfAllRequests.xlsx';
        document.body.appendChild(element);
        element.click();
        // this.toastr.success('File exported successfully', 'Success!');
      }, (error:any)  => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      });
  }
  ExportMonthlyCheck() {
    this.exportDataService.exportMonthlyCheck().subscribe(
      (res: any) => {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(res.image);
        element.download = 'MonthlyCheck.xlsx';
        document.body.appendChild(element);
        element.click();
        // this.toastr.success('File exported successfully', 'Success!');
      }, (error:any)  => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      });
  }
  ExportRental() {
    this.exportDataService.exportRental().subscribe(
      (res: any) => {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(res.image);
        element.download = 'Rental.xlsx';
        document.body.appendChild(element);
        element.click();
        // this.toastr.success('File exported successfully', 'Success!');
      }, (error:any)  => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      });
  }
  ExportRentalNaplesDC() {
    this.exportDataService.exportRentalNaplesDC().subscribe(
      (res: any) => {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(res.image);
        element.download = 'Rental_NaplesDC.xlsx';
        document.body.appendChild(element);
        element.click();
        // this.toastr.success('File exported successfully', 'Success!');
      }, (error:any) => {
        this.errorHandler.handleError(error);
        this.errorMessage = this.errorHandler.errorMessage;
      });
  }
  //#endregion

}
