import { Injectable } from '@angular/core';
import { RepositoryService } from '../../shared/services/repository.service';
import { Observable } from 'rxjs';
import { People } from '../../core/interfaces/people';
import { HttpClient, HttpResponse } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PeopleService {

  private Url = 'italypcreqapi/people';

  constructor(private repo: RepositoryService, private httpClient: HttpClient) { }

  getFilteredPeople(account: string, includeGroups?: boolean): Observable<any[]> {
    return this.repo.getData(this.Url + '/Filter' + `/${account}`);
  }
  isValidAccount(account: string): Observable<any> {
    return this.repo.getData(this.Url + '/IsValidAccount' + `/${account}`);
  }
  getProfile(account: string): Observable<People> {
    return this.repo.getData(this.Url + '/GetProfile' + `/${account}`);
  }
  getProfilePicture(eid: string): Observable<any> {
    return this.repo.getData(this.Url + '/GetUserProfileImg' + `/${eid}`);
  }

}
