<div class="body">

    <div class="container">
        <mat-grid-list cols="3" rowHeight="2:1" gutterSize="30px">
            <mat-grid-tile *ngIf="!AppUser?.isServiceProvider">
                <a href="https://wsadmin.accenture.com/" target="_blank">
                    <div id="box">
                        <div class="box1 text-center">
                            <fa-icon [icon]="faExclamation"></fa-icon>
                        </div>
                        <div class="box2 text-left">
                            <h4><b>PASSWORD SHARING</b></h4>
                            <p class="homeText">
                                Before creating a new PC Request, it is necessary
                                to enter the PC's Assignee credentials (EnterpriseID/Password)
                            </p>
                        </div>
                    </div>
                </a>
            </mat-grid-tile>

            <mat-grid-tile *ngIf="!AppUser?.isServiceProvider" routerLink="/PCRequestCreate/{{AppUser?.enterpriseId}}">
                <div id="box">
                    <div class="box1 text-center">
                        <fa-icon [icon]="faPlusSquare"></fa-icon>
                    </div>
                    <div class="box2 text-left">
                        <h4><b>NEW PC REQUEST</b></h4>
                        <p class="homeText">
                            Once the request is completed, the delivery of
                            the PC will be carried out after 5 working days
                        </p>
                    </div>
                </div>
            </mat-grid-tile>

            <mat-grid-tile
                *ngIf="AppUser?.isDeveloper || AppUser?.isAdministrator || AppUser?.isSuperUser || AppUser?.isSuperUserPlus"
                routerLink="/PCRequest/All">
                <div id="box">
                    <div class="box1 text-center">
                        <fa-icon [icon]="faLaptop"></fa-icon>
                    </div>
                    <div class="box2 text-left">
                        <h4><b>All PC Requests</b></h4>
                        <div style="margin-top: 10px;" *ngIf="loadingAnalytics">
                            <mat-progress-spinner [mode]="'indeterminate'" diameter="30">
                            </mat-progress-spinner>
                        </div>
                        <h4>{{analytics?.allPCRequestsCount}}</h4>
                    </div>
                </div>
            </mat-grid-tile>

            <mat-grid-tile
                *ngIf="AppUser?.isDeveloper || AppUser?.isAdministrator || AppUser?.isSuperUser || AppUser?.isSuperUserPlus"
                routerLink="/PCRequest/Archived">
                <div id="box">
                    <div class="box1 text-center">
                        <fa-icon [icon]="faArchive"></fa-icon>
                    </div>
                    <div class="box2 text-left">
                        <h4><b>ALL ARCHIVED REQUESTS</b></h4>
                        <div style="margin-top: 10px;" *ngIf="loadingAnalytics">
                            <mat-progress-spinner [mode]="'indeterminate'" diameter="30">
                            </mat-progress-spinner>
                        </div>
                        <h4>{{analytics?.allArchivedPCRequestsCount}}</h4>
                    </div>
                </div>
            </mat-grid-tile>

            <mat-grid-tile
                *ngIf="AppUser?.isSuperUser"
                routerLink="/PCRequest/Naples">
                <div id="box">
                    <div class="box1 text-center">
                        <fa-icon [icon]="faArchive"></fa-icon>
                    </div>
                    <div class="box2 text-left">
                        <h4><b>ALL PC REQUESTS - NAPLES DC</b></h4>
                        <div style="margin-top: 10px;" *ngIf="loadingAnalytics">
                            <mat-progress-spinner [mode]="'indeterminate'" diameter="30">
                            </mat-progress-spinner>
                        </div>
                        <h4>{{analytics?.allNaplesDCPCRequestsCount}}</h4>
                    </div>
                </div>
            </mat-grid-tile>

            <mat-grid-tile *ngIf="AppUser?.isServiceProvider" routerLink="/PCRequest/RequestsToProcess">
                <div id="box">
                    <div class="box1 text-center">
                        <fa-icon [icon]="faCheckSquare"></fa-icon>
                    </div>
                    <div class="box2 text-left">
                        <h4><b>REQUEST TO PROCESS</b></h4>
                        <div style="margin-top: 10px;" *ngIf="loadingAnalytics">
                            <mat-progress-spinner [mode]="'indeterminate'" diameter="30">
                            </mat-progress-spinner>
                        </div>
                        <h4>{{analytics?.requestsToProcessCount}}</h4>
                    </div>
                </div>
            </mat-grid-tile>

            <mat-grid-tile *ngIf="AppUser?.isServiceProvider" routerLink="/PCRequest/RequestsToProcessNaplesDC">
                <div id="box">
                    <div class="box1 text-center">
                        <fa-icon [icon]="faCheckSquare"></fa-icon>
                    </div>
                    <div class="box2 text-left">
                        <h4><b>REQUEST TO PROCESS - NAPLES DC</b></h4>
                        <div style="margin-top: 10px;" *ngIf="loadingAnalytics">
                            <mat-progress-spinner [mode]="'indeterminate'" diameter="30">
                            </mat-progress-spinner>
                        </div>
                        <h4>{{analytics?.requestsToProcessNaplesDCCount}}</h4>
                    </div>
                </div>
            </mat-grid-tile>
            <mat-grid-tile
                *ngIf="!AppUser?.isServiceProvider && !AppUser?.isDeveloper && !AppUser?.isAdministrator && !AppUser?.isSuperUser && !AppUser?.isSuperUserPlus"
                routerLink="/PCRequest/All">
                <div id="box">
                    <div class="box1 text-center">
                        <fa-icon [icon]="faLaptop"></fa-icon>
                    </div>
                    <div class="box2 text-left">
                        <h4><b>MY PC REQUEST</b></h4>
                        <div style="margin-top: 10px;" *ngIf="loadingAnalytics">
                            <mat-progress-spinner [mode]="'indeterminate'" diameter="30">
                            </mat-progress-spinner>
                        </div>
                        <h4>{{analytics?.userPCRequestsCount}}</h4>
                    </div>
                </div>
            </mat-grid-tile>

            <mat-grid-tile
                *ngIf="!AppUser?.isServiceProvider && !AppUser?.isDeveloper && !AppUser?.isAdministrator && !AppUser?.isSuperUser && !AppUser?.isSuperUserPlus"
                routerLink="/PCRequest/Archived">
                <div id="box">
                    <div class="box1 text-center">
                        <fa-icon [icon]="faArchive"></fa-icon>
                    </div>
                    <div class="box2 text-left">
                        <h4><b>MY ARCHIVED REQUESTS</b></h4>
                        <div style="margin-top: 10px;" *ngIf="loadingAnalytics">
                            <mat-progress-spinner [mode]="'indeterminate'" diameter="30">
                            </mat-progress-spinner>
                        </div>
                        <h4>{{analytics?.userArchivedPCRequestsCount}}</h4>
                    </div>
                </div>
            </mat-grid-tile>

            <mat-grid-tile colspan="3">
                <div class="newsBox">
                    <div *ngIf="loadingAnalytics" style="margin: auto; width: fit-content;">
                        <mat-progress-spinner [mode]="'indeterminate'" diameter="40">
                        </mat-progress-spinner>
                    </div>
                    <div *ngFor="let news of analytics?.activeNews" [innerHTML]="news.description" style="padding: 10px;">
                    </div>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>