import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExportDataService {

  private Url = 'italypcreqapi/report';

  constructor(private repo: RepositoryService, private http: HttpClient) { }
  public exportAllOfAll() {
    return this.http.get(this.Url + '/AllOfAll', { observe: 'response', responseType: 'blob' }).pipe(map((res: HttpResponse<Blob>) => {
      const data = {
        image: new Blob([res.body as BlobPart], { type: res.headers.get('Content-Type') || '' }),
        contentDisposition: res.headers.get('content-disposition')
      };
      return data;
    }));
  }
  public exportMonthlyCheck() {
    return this.http.get(this.Url + '/MonthlyCheck', { observe: 'response', responseType: 'blob' }).pipe(map((res: HttpResponse<Blob>) => {
      const data = {
        image: new Blob([res.body as BlobPart], { type: res.headers.get('Content-Type') || '' }),
        contentDisposition: res.headers.get('content-disposition')
      };

      return data;
    }));
  }
  public exportRental() {
    return this.http.get(this.Url + '/Rental', { observe: 'response', responseType: 'blob' }).pipe(map((res: HttpResponse<Blob>) => {
      const data = {
        image: new Blob([res.body as BlobPart], { type: res.headers.get('Content-Type') || '' }),
        contentDisposition: res.headers.get('content-disposition')
      };

      return data;
    }));
  }
  public exportRentalNaplesDC() {
    return this.http.get(this.Url + '/RentalNaplesDC', { observe: 'response', responseType: 'blob' }).pipe(map((res: HttpResponse<Blob>) => {
      const data = {
        image: new Blob([res.body as BlobPart], { type: res.headers.get('Content-Type') || '' }),
        contentDisposition: res.headers.get('content-disposition')
      };

      return data;
    }));
  }
  public exportRequestsToProcess(isNaples: boolean) {
    return this.http.get(this.Url + '/RequestsToProcess?isNaples=' + `${isNaples}`, { observe: 'response', responseType: 'blob' }).pipe(map((res: HttpResponse<Blob>) => {
      const data = {
        image: new Blob([res.body as BlobPart], { type: res.headers.get('Content-Type') || ''}),
        contentDisposition: res.headers.get('content-disposition')
      };

      return data;
    }));
  }
}
