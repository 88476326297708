import {AppUser} from 'src/app/core/interfaces/AppUser';
import {ErrorLog} from 'src/app/core/interfaces/errorLog';
import {EventLog} from 'src/app/core/interfaces/eventLog';
import {MailTemplate} from 'src/app/core/interfaces/mailTemplate';
import {News} from 'src/app/core/interfaces/news';
import {ChargeCode} from '../../core/interfaces/chargeCode';
import {MailPriority} from '../../core/interfaces/mailPriority';
import {PcModel} from '../../core/interfaces/PcModel';
import {OfficeDelivery} from "../../core/interfaces/officeDelivery";
import { People } from '../../core/interfaces/people';
import { PcRequest1 } from 'src/app/core/interfaces/PcRequest1';
import { PcType } from 'src/app/core/interfaces/PcType';
import { PcRequestType } from 'src/app/core/interfaces/pcRequestType';
import { PcRequestStatus } from 'src/app/core/interfaces/pcRequestStatus';
import { PcRequest } from 'src/app/core/interfaces/pcrequest';
import { Setting } from 'src/app/core/interfaces/setting';
import { User } from 'src/app/core/interfaces/user';

export class InitObjects {



  static getInitChargeCode(): ChargeCode {
    let initChargeCode: ChargeCode = {
      lastClosedWBSNotificationSentDate: new Date(),
      id: 0,
      requestor: '',
      assignee: '',
      assigneeLocation: '',
      sponsor: '',
      wbsdelegate: '',
      chargeCode: '',
    };

    return initChargeCode;
  }

  static getInitErrorLog(): ErrorLog {
    let initErrorLog: ErrorLog = {
      id: 0,
      machineName: '',
      exceptionType: '',
      exceptionMessage: '',
      exceptionSource: '',
      targetSiteModule: '',
      targetSiteName: '',
      stackTrace: '',
      helpLink: '',
      userId: '',
      exceptionDate: new Date(),
    };

    return initErrorLog;
  }

  static getInitEventLog(): EventLog {
    let initEventLog: EventLog = {
      id: 0,
      machineName: '',
      eventMessage: '',
      browser: '',
      userId: '',
      eventDate: new Date(),
    };

    return initEventLog;
  }

  static getInitAppUser(): AppUser {
    let initAppUser: AppUser = {
      enterpriseId: '',
      isDeveloper: false,
      isAdministrator: false,
      isServiceProvider: false,
      isSuperUser: false,
      isSuperUserPlus: false,
      roles: [],
    };

    return initAppUser;
  }

  static getInitMailTemplate(): MailTemplate {
    let initMailTemplate: MailTemplate = {
      id: 0,
      key: '',
      subject: '',
      to: '',
      cc: '',
      bcc: '',
      mailPriorityId: 0,
      body: '',
      mailPriority: this.getInitMailPriority(),
    };

    return initMailTemplate;
  }

  static getInitMailPriority(): MailPriority {
    let initMailPriority: MailPriority = {
      id: 0,
      name: '',
      displayOrder: 0,
    };
    return initMailPriority;
  }

  static getInitNews(): News {
    let initNews: News = {
      id: 0,
      description: '',
      active: false,
      position: 0,
    };

    return initNews;
  }

  static getInitPcModel(): PcModel {
    let initPcModel: PcModel = {
      id: 0,
      description: '',
      pctypeId: 0,
      active: false,
    };

    return initPcModel;
  }

 static getInitOfficeDelivery(): OfficeDelivery {
    let initOfficeDelivery :   OfficeDelivery = {
      id: 0,
      description: '',
      active: false
    }
    return (initOfficeDelivery);
  }

  static getInitPeople(): People {
    let initPeople :  People = {
      lastName:  '',
      firstName:  '',
      personnelNumber:  '',
      companyCode:  '',
      companyDescription:  '',
      levelDescription:  '',
      jobFamilyDescription:  '',
      careerTrackDescription:  '',
      countryDescription:  '',
      location:  '',
      costCenterCode:  '',
      costCenterDescription:  '',
      mobile:  '',
      workforceCode:  '',
      workforceDescription:  '',
      assigneeAd: false
  };
  return initPeople;
  }

  static getInitPcRequest1(): PcRequest1 {
   let initPcRequest1 : PcRequest1 = {
    id: 0,
    assignee: '',
    sponsor: '',
    requestor: '',
    wbsdelegate: '',
    chargeCode: '',
    serialNumber: '',
    requestType: '',
    requestStatus: '',
    assigneeEmploymentStatus: '',
    created: new Date(),
    updated: new Date(),

    // PcRequest1
    officeDelivery: this.getInitOfficeDelivery(),
    assigneePhone: '',
    assigneeCompanyCd: '',
    notes: '',

    // PcRequest2
    pcprice: 0,

    // OTHER
    createdBy: '',
    updatedBy: '',
    pctype: this.getInitPcType(),
    pcmodel: this.getInitPcModel(),
    pcrequestStatus: this.getInitPcRequestStatus(),
    pcrequestType: this.getInitPcRequestType(),
    isNaplesDc: false,
    assigneeLevel: '',
    assigneeFirstName: '',
    assigneeLastName: '',
    assigneePersonnelNbr: '',
    assigneeCareerTrack: '',
    assigneeCountry: '',
    assigneeLocation: '',
    assigneeCostCenter: '',
    uplift: false,
    optionalPc: false,
    //SPONSOR fields
    sponsorFirstName: '',
    sponsorLastName: '',
    sponsorPersonnelNbr: '',
    sponsorCompanyCd: '',
    sponsorLevel: '',
    sponsorWorkforceResp: '',
    sponsorCountry: '',
    sponsorLocation: '',
    sponsorCostCenter: ''
   };

   return initPcRequest1;
  }

  static getInitPcRequestType(): PcRequestType {
    let initPcRequestType : PcRequestType = {
      id: 0,
      description: ''

    };

    return initPcRequestType;
  }
  static getInitPcRequestStatus(): PcRequestStatus {
    let initPcRequestStatus : PcRequestStatus = {
      id: 0,
      description: ''
    };

    return initPcRequestStatus;
  }

  static getInitPcType(): PcType {
   let initPcType : PcType = {
    id: 0,
    description: '',
    price: 0,
   };

   return initPcType;
  }

  static getInitPcRequest(): PcRequest {
    let initPcRequest : PcRequest = {
      id: 0,
      assignee: '',
      sponsor: '',
      requestor: '',
      wbsdelegate: '',
      chargeCode: '',
      serialNumber: '',
      requestType: '',
      requestStatus: '',
      assigneeEmploymentStatus: '',
      created: new Date(),
      updated: new Date(),

      // PcRequest1
      pcType: '',
      officeDelivery: '',
      assigneePhone: '',
      assigneeCompanyCd: '',
      notes: '',

      // PcRequest2
      pcprice: 0,
      uplift: false,
      optionalPc: false


    };

    return initPcRequest;
  }

  static getInitSetting(): Setting {
    let initSetting : Setting = {
      key: '',
      value: ''
     };

     return initSetting;

  }

  static getInitUser(): User {
    let initUser: User = {
    account: '',
    created: new Date(),
    id: 0,
    isDomainGroup: false,
    roles: [],
    updated: new Date()
  };
  return initUser;
  }
}
