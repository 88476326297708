import { Injectable } from '@angular/core';
@Injectable()
export class DatadogSessionService {
  setDataDogSessionId(): string {
    let dduserid = this.getCookie('_dduserid');
    if (dduserid == '') {
      dduserid = this.uuidv4();
      if (dduserid != '' && dduserid != null) {
        this.setCookie('_dduserid', dduserid, 30);
      }
    }
    return dduserid;
  }
  setCookie(name: string, val: string, exdays: number): void {
    const date = new Date();
    const value = val;

    // Set it expire in 1 day
    date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);

    const expires = 'expires=' + date.toUTCString();
    // Set it
    document.cookie = name + '=' + value + ';' + expires + ';path=/;secure';
  }
  getCookie(cname: string): string {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  getRandomSymbol(): string {
    const array = new Uint8Array(1);
    window.crypto.getRandomValues(array);
    return (array[0] % 16).toString(16);
  }
  uuidv4(): string {
    return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      this.getRandomSymbol
    );
  }
}
