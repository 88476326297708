import { Inject, Injectable } from '@angular/core';
import { AppConfigService } from '../services/app-config.service';
import { RebarAuthModule } from './rebar.auth.module';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: RebarAuthModule,
})
export class RebarAuthService {
  private readonly destroying$ = new Subject<void>();
  public authObserver$: Observable<boolean>;
  constructor(
    config: AppConfigService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private auth: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        if (this.authenticationEnabled() && !this.IsUserAuthenticated()) {
          this.login();
        }
      });

    this.authObserver$ = this.msalBroadcastService.msalSubject$.pipe(
      tap(console.log),
      map((event) => {
        console.log(event);
        return (
          event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType == EventType.HANDLE_REDIRECT_END
        );
      })
    );
  }

  public IsUserAuthenticated(): boolean {
    return this.auth.instance.getAllAccounts().length > 0;
  }

  public authenticationEnabled(): boolean {
    return environment.providers !== 'mock';
  }

  checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.auth.instance.getActiveAccount();

    if (!activeAccount && this.auth.instance.getAllAccounts().length > 0) {
      const accounts = this.auth.instance.getAllAccounts();
      this.auth.instance.setActiveAccount(accounts[0]);
    }
  }

  public login(): void {
    if (environment.providers !== 'mock') {
      const activeAccount = this.auth.instance.getActiveAccount();

      if (!activeAccount) {
        if (this.auth.instance.getAllAccounts().length > 0) {
          const accounts = this.auth.instance.getAllAccounts();
          this.auth.instance.setActiveAccount(accounts[0]);
        } else {
          if (this.msalGuardConfig.authRequest) {
            this.auth.loginRedirect({
              ...this.msalGuardConfig.authRequest,
            } as RedirectRequest);
          } else {
            this.auth.loginRedirect();
          }
        }
      }
    }
  }

  public logout(): void {
    this.auth.logout();
  }
  public getUser(): string | undefined {
    return this.auth.instance.getActiveAccount()?.username;
  }
}
