import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf } from '@angular/common';
/* import { discoverLocalRefs } from '@angular/core/src/render3/context_discovery'; */


@Component({
    selector: 'rebar-support-box',
    templateUrl: './support-box.component.html',
    styleUrls: ['./support-box.component.css'],
    standalone: true,
    imports: [NgIf, FaIconComponent]
})
export class SupportBoxComponent implements OnInit {

  faPhone = faPhone;
  faEnvelope = faEnvelope;

  @Input() supportbox = false;
  @Input() aboutbox = false;

  @Output() messageEvent = new EventEmitter<boolean>();

  constructor() { }

  CloseIt() {
    this.messageEvent.emit(false);
  }

  ngOnInit() {
  }

}
