<div class="container">
  <br/><br/>
  <div class="jumbotron">
    
      <h1>
          <fa-icon [icon]="faExclamationTriangle"></fa-icon>        
          &nbsp;ACCESS NOT AUTHORIZED
      </h1>
      <h2>No authorization to access the requested page found.</h2>  
      <button routerLink="/home" class="btn btn-outline-info" style="float: right;">Go Home <fa-icon [icon]="faHome"></fa-icon></button>
    </div>
  </div>
  