import { Component, Inject, OnInit } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { AppConfigService } from './core/services/app-config.service';
import { DatadogSessionService } from './core/services/datadog-session.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [
        NgIf,
        HeaderComponent,
        FooterComponent,
    ],
})
export class AppComponent implements OnInit {
  title = 'italypcreq-portal';
  isVisible = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private appConfigService: AppConfigService,
    private datadogSessionService: DatadogSessionService
  ) { }

  // constructor(
  //   private rebarAuthService: RebarAuthService,
  //   private appConfigService: AppConfigService,
  //   private datadogSessionService: DatadogSessionService
  // ) {}

  ngOnInit(): void {

    this.authService.handleRedirectObservable().subscribe();

    this.setLoginDisplay();
    if (!this.loginDisplay) this.login();

    this.authService.instance.enableAccountStorageEvents(); // Register the storage listener that will be emitting the events
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        // Optional filtering of events
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_REMOVED),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          // Account logged out in a different tab
          this.logout();
        } else {
          // Update UI to show user is signed in. result.payload contains the account that was logged in
        }
      });
    datadogRum.init({
      applicationId: (
        this.appConfigService.config['datadog'] as Record<string, string>
      )['appId'],
      clientToken: (
        this.appConfigService.config['datadog'] as Record<string, string>
      )['clientToken'],
      site: 'datadoghq.com',
      service: (
        this.appConfigService.config['datadog'] as Record<string, string>
      )['service'],
      env: (this.appConfigService.config['datadog'] as Record<string, string>)[
        'environment'
      ],
      sampleRate: 100,
      trackInteractions: true,
    });
    datadogRum.setUser({
      id: this.datadogSessionService.setDataDogSessionId(),
    });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  
  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  // ngOnInit(): void {
  //   this.rebarAuthService.authObserver$.subscribe((authStatus) => {
  //     console.log(`user is logged in ${authStatus}`);

  //     if (authStatus) {
  //       if (!this.rebarAuthService.authenticationEnabled())
  //         this.isVisible = true;
  //       if (
  //         this.rebarAuthService.authenticationEnabled() &&
  //         this.rebarAuthService.IsUserAuthenticated()
  //       )
  //         this.isVisible = true;
  //     }
  //   });

  //   datadogRum.init({
  //     applicationId: (
  //       this.appConfigService.config['datadog'] as Record<string, string>
  //     )['appId'],
  //     clientToken: (
  //       this.appConfigService.config['datadog'] as Record<string, string>
  //     )['clientToken'],
  //     site: 'datadoghq.com',
  //     service: (
  //       this.appConfigService.config['datadog'] as Record<string, string>
  //     )['service'],
  //     env: (this.appConfigService.config['datadog'] as Record<string, string>)[
  //       'environment'
  //     ],
  //     sampleRate: 100,
  //     trackInteractions: true,
  //   });
  //   datadogRum.setUser({
  //     id: this.datadogSessionService.setDataDogSessionId(),
  //   });
  // }
}
