import { enableProdMode, APP_INITIALIZER, importProvidersFrom, ErrorHandler } from '@angular/core';

import { MSALInstanceFactory, MSALInterceptorConfigFactory, MSALGuardConfigFactory } from './app/app.module';
import { environment } from './environments/environment';
import { AppConfigService } from './app/core/services/app-config.service';
import { AppComponent } from './app/app.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { ToastrModule } from 'ngx-toastr';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { REBAR_AUTH_GUARD, RebarAuthModule } from './app/core/rebarauth/rebar.auth.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalModule } from '@azure/msal-angular';
import { AddApiDomainInterceptorService } from './app/helpers/add-api-domain-interceptor/add-api-domain-interceptor.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { DatadogSessionService } from './app/core/services/datadog-session.service';
import { RouterModule, provideRouter } from '@angular/router';
import { HomeComponent } from './app/feature/home/home.component';
import { ExportComponent } from './app/feature/export/export.component';
import { PcRequestCreateComponent } from './app/feature/pc-request/pc-request-create/pc-request-create.component';
import { PcRequestDetailComponent } from './app/feature/pc-request/pc-request-detail/pc-request-detail.component';
import { PcRequestEditComponent } from './app/feature/pc-request/pc-request-edit/pc-request-edit.component';
import { PcRequestComponent } from './app/feature/pc-request/pc-request.component';
import { PageNotAuthorizedComponent } from './app/shared/page-not-authorized/page-not-authorized.component';
import { PageNotFoundComponent } from './app/shared/page-not-found/page-not-found.component';

const protectedResourceMap = new Map<string, Array<string>>();



if (environment.production) {
  enableProdMode();
}

function bootstrapApp(): void {
  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(BrowserModule, RouterModule, FormsModule, RebarAuthModule.forRoot(), FontAwesomeModule, ToastrModule.forRoot(), MatButtonModule, MatCheckboxModule, MatMenuModule, MatIconModule, MatToolbarModule, MatSidenavModule, MatListModule, MatPaginatorModule, MatExpansionModule, NgbModule, MatSelectModule, ReactiveFormsModule, MatFormFieldModule, MatAutocompleteModule, MatInputModule, MatCardModule, MatDialogModule, MatSlideToggleModule, MatProgressBarModule, MatTooltipModule, AngularEditorModule, MatProgressSpinnerModule, MatGridListModule, MsalModule),
      {
        provide: APP_INITIALIZER,
        useFactory: (config: AppConfigService) => () => config.load(),
        deps: [AppConfigService],
        multi: true,
      },
      DatadogSessionService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AddApiDomainInterceptorService,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true,
      },
      {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory,
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory,
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory,
      },

      MsalService,
      MsalGuard,
      MsalBroadcastService,
      provideHttpClient(withInterceptorsFromDi()),
      provideAnimations(),
      provideRouter([
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'Home'
        },
        {
          path: 'Home', component: HomeComponent, canActivate: [REBAR_AUTH_GUARD]
        },
        {
          path: 'Admin',
          loadChildren: () => import('./app/feature/admin/admin.routes').then(r => r.ADMIN_ROUTES),
          canActivate: [REBAR_AUTH_GUARD]
        },
        {
          path: 'PCRequest',
          loadChildren: () => import('./app/feature/pc-request/pc-request.routes').then(r => r.PCREQUESTS_ROUTES),
          canActivate: [REBAR_AUTH_GUARD]
        },
        {
          path: 'Export/:type',
          component: ExportComponent,
          canActivate: [REBAR_AUTH_GUARD]
        },
        {
          path: 'not-authorized',
          component: PageNotAuthorizedComponent,
        },
        {
          path: 'not-found',
          component: PageNotFoundComponent,
        },

        // {
        //   path: 'PCRequest/:type',
        //   component: PcRequestComponent,
        //   canActivate: [REBAR_AUTH_GUARD]

        // },
        // {
        //   path: 'PCRequestCreate/:eid',
        //   component: PcRequestCreateComponent,
        //   canActivate: [REBAR_AUTH_GUARD]
        // },
        // {
        //   path: 'PCRequestEdit/:id',
        //   component: PcRequestEditComponent,
        //   canActivate: [REBAR_AUTH_GUARD]

        // },
        // {
        //   path: 'PCRequestDetail/:id',
        //   component: PcRequestDetailComponent,
        //   canActivate: [REBAR_AUTH_GUARD]

        // },
      
        {
          path: '**',
          redirectTo: 'not-found',
        }

      ]),
    ]
  })
    .catch((err) => console.log(err));
}

document.addEventListener('DOMContentLoaded', () => {
  // We bootstraping app from browser, we need fetch config.json.
  fetch(AppConfigService.configPath)
    .then((response) => {
      return response.json();
    })
    .then((jsonData) => {
      sessionStorage[AppConfigService.configPath] = JSON.stringify(jsonData);
      bootstrapApp();
    })
    .catch((err) => {
      console.warn('caught when bootstrapping app');
      console.error(err);
    });
});
