<div id="fullDiv" *ngIf="supportbox">
  <div class="container" style="width: 35rem;">
    <div id="BOX" class="card" style="width: 35rem;  background-color: #F3F5F6 !important;">
      <div class="card-body">
        <h6 class="card-subtitle mb-2 text-muted">Per assegnazioni PC al personale ATS dell’ufficio di Napoli:</h6>
        <p class="card-text">Phone <fa-icon [icon]="faPhone"></fa-icon> : 081-0179220 (9:00/13:00 – 14:00/18:00) <br>
          Email <fa-icon [icon]="faEnvelope"></fa-icon> : <a href="#" class="card-link">TS.Naples&#64;accenture.com</a></p>
        <br>
        <h6 class="card-subtitle mb-2 text-muted">Per tutti gli altri: </h6>
        <p class="card-text">Phone <fa-icon [icon]="faPhone"></fa-icon> : 02-00647934 (9:00/13:00 - 14:00/18:00)
          <br>Email <fa-icon [icon]="faEnvelope"></fa-icon> : <a href="#"
            class="card-link">segreteria.asystel&#64;accenture.com</a></p>
      </div>
    </div>
    <button type="button" class="btn btn-light" (click)="CloseIt()">Close</button>
  </div>
</div>

<div id="fullDiv" *ngIf="aboutbox">
  <div class="container" style="width: 35rem;">
    <div id="BOX" class="card" style="width: 35rem;  background-color: #F3F5F6 !important;">
      <div class="card-body">
        <h6 class="card-subtitle mb-2 text-muted">Italy PC Request v3.1.45</h6>
        <p class="card-text">© 2019 Accenture. All Rights Reserved. Accenture Confidential. For Internal Use Only.</p>
      </div>
    </div>
    <button type="button" class="btn btn-light" (click)="CloseIt()">Close</button>
  </div>
</div>