import { Component, OnInit } from '@angular/core';
import { faExclamationTriangle, faHome } from '@fortawesome/free-solid-svg-icons';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'rebar-page-not-authorized',
    templateUrl: './page-not-authorized.component.html',
    styleUrls: ['./page-not-authorized.component.css'],
    standalone: true,
    imports: [FaIconComponent, RouterLink]
})
export class PageNotAuthorizedComponent implements OnInit {

  faExclamationTriangle = faExclamationTriangle;
  faHome = faHome;

  constructor() { }

  ngOnInit() {
  }

}
