<div id="footer">
    <ul class="nav fixed-bottom ">
        <li class="nav-item">
            <b><a class="nav-link" href="https://in.accenture.com/protectingaccenture/data-security/5422-2/"
                    target="_blank" style="padding: 5px 10px!important">Privacy Policy</a></b>
        </li>
        <li class="nav-item">
            <b><a class="nav-link" href="https://www.accenture.com/us-en/about/company-cookies-similar-technology"
                    target="_blank" style="padding: 5px 10px!important">Cookie Policy</a></b>
        </li>
        <li class="nav-item">
            <b><a class="nav-link" [routerLink]="[]" (click)="OpenTerms()" style="padding: 5px 10px!important">Terms of Use</a></b>
        </li>
        <li>
            <span style="margin-left:160px"></span>
        </li>
        <li>
            <p style="margin: auto; color: #212529;" class="navbar-text"> &copy; 2001-{{currentYear}} Accenture. All rights
                reserved. Accenture Confidential. For
                internal use only.</p>
        </li>
    </ul>

</div>

<div id="fullDiv" *ngIf="termsBox">
    <div class="container" style="width: 35rem;">
        <div id="BOX" class="card" style="width: 35rem;  background-color: #F3F5F6 !important;">
            <div class="card-body">
                <p class="card-text">
                    This system is the property of Accenture, and is to be used in accordance with applicable Accenture
                    Policies.
                    Unauthorized access or activity is a violation of Accenture Policies and may be a violation of law.
                    Use of this
                    system constitutes consent to monitoring for unauthorized use, in accordance with Accenture
                    Policies, local laws,
                    and regulations. Unauthorized use may result in penalties including, but not limited to, reprimand,
                    dismissal,
                    financial penalties, and legal action.
                </p>
            </div>
        </div>
        <button type="button" class="btn btn-light" (click)="CloseTerms()">Close</button>
    </div>
</div>