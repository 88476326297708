import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  constructor(private http: HttpClient) { }

  public getData(route: string){
    return this.http.get<any>(route);
  }

  public create(route: string, body:any){
    return this.http.post(route, body, this.generateHeaders());
  }

  public update(route: string, body:any){
    return this.http.put(route, body, this.generateHeaders());
  }

  public delete(route: string){
    return this.http.delete(route);
  }

  private createCompleteRoute(route: string, envAddress: string){
    return `${route}`;
  }

  public downloadData(route: string){
    return this.http.get(route, { observe: 'response', responseType: 'blob' });
  }

  private generateHeaders(){
    return {
      headers: new HttpHeaders({'Content-type': 'application/json'})
    };
  }
}
