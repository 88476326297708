import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../../../core/interfaces/user';
import { Observable, of } from 'rxjs';
import { role } from '../../../core/interfaces/role';
import { UserForCreation } from '../../../core/interfaces/userForCreation';
import { RepositoryService } from '../../../shared/services/repository.service';
import { AppUser } from '../../../core/interfaces/AppUser';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private userUrl = 'italypcreqapi/user';

  constructor(private repo: RepositoryService) { }

  getAppUser(): Observable<AppUser> {
    return this.repo.getData(this.userUrl + '/GetApplicationUser');
  }

  getUsers(): Observable<User[]> {
    return this.repo.getData(this.userUrl + '/GetAllUsers');
  }

  getRoles(): Observable<role[]> {
    return this.repo.getData(this.userUrl + '/GetAllRoles');
  }

  getUserById(Id: number) {
    return this.repo.getData(this.userUrl + '/GetUserById' + `/${Id}`);
  }

  getUserByEnterpriseId(account: string) {
    return this.repo.getData(this.userUrl + '/GetUserByEnterpriseId' + `/${account}`);
  }

  createUser(body: any) {
    return this.repo.create(this.userUrl + '/CreateUser', body);
  }

  updateUser(body: any, Id: number) {
    return this.repo.update(this.userUrl + '/UpdateUser' + `/${Id}`, body);
  }

  deleteUser(Id: number) {
    return this.repo.delete(this.userUrl + '/DeleteUser' + `/${Id}`);
  }

}
