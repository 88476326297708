import { Component, OnInit } from '@angular/core';
import { faExclamation, faPlusSquare, faLaptop, faArchive, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { UsersService } from '../users/service/users.service';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';
import { AppUser } from '../../core/interfaces/AppUser';
import { AnalyticsService } from '../../shared/services/analytics.service';
import { Analytics } from '../../core/interfaces/Analytics';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { FaIconComponent, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor, CommonModule } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';


@Component({
    selector: 'rebar-home',
    templateUrl: './home.html',
    styleUrls: ['./home.component.css'],
    standalone: true,
    imports: [MatGridListModule, NgIf, FaIconComponent, RouterLink, NgFor,FontAwesomeModule, CommonModule,
        MatProgressSpinnerModule,
        MatGridListModule]
})

export class HomeComponent implements OnInit {

    constructor(
        private userService: UsersService,
        private analyticsService: AnalyticsService,
        private errorHandler: ErrorHandlerService) { }

    faExclamation = faExclamation;
    faPlusSquare = faPlusSquare;
    faLaptop = faLaptop;
    faArchive = faArchive;
    faCheckSquare = faCheckSquare;

    message = 'Home Page';
    user = 'REBAR';

    AppUser: AppUser={
      enterpriseId: '',
      isDeveloper: false,
      isAdministrator: false,
      isServiceProvider: false,
      isSuperUser: false,
      isSuperUserPlus: false,
      roles: []
  };
    errorMessage='';

    analytics: Analytics = {
      userPCRequestsCount : 0,
      allPCRequestsCount : 0,
      allNaplesDCPCRequestsCount : 0,
      userArchivedPCRequestsCount : 0,
      allArchivedPCRequestsCount : 0,
      requestsToProcessCount : 0,
      requestsToProcessNaplesDCCount : 0,
      activeNews:[]
  };
    loadingAnalytics = false;

    setMessage(newMessage: string) {
        this.message = newMessage;
    }

    clearMessage() {
        this.message = '';
    }

    ngOnInit() {
         this.loadAppUser();
         this.loadAnalytics();
    }
    loadAppUser() {
         this.userService.getAppUser().subscribe(
             item => {
                 this.AppUser = item;
             }, error => {
                 this.errorHandler.handleError(error);
                 this.errorMessage = this.errorHandler.errorMessage;
             });
    }
    loadAnalytics() {
        this.loadingAnalytics = true;
        this.analyticsService.getAnalytics().subscribe(
            item => {
                this.analytics = item;
                this.loadingAnalytics = false;
            }, error => {
                this.errorHandler.handleError(error);
                this.errorMessage = this.errorHandler.errorMessage;
            });
    }
}

